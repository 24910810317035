@import "_variables";
@import "_placeholders";
@import "_mixins";

// ============================
// Navbar
// ============================

.navbar {
  // background-color: $yellow-faded;
}

.nav-link {
  display: inline-grid;
}

.nav-link.dropdown-toggle:after {
  display: none;
}

.navbar__social {
  background: $green;
  color: $white;
  height: 25px;
  width: 25px;
  font-size: .9rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// ============================
// Nav Item
// ============================

.nav-ul {
  font-size: 1.1em;
  font-weight: bold;
}

// ============================
// Nav Toggler
// ============================

.navbar-toggler {
  color: $brand;
  border: none;
  z-index: 10002;
  font-size: 1.5rem;

  .fa-times {
    display: none;
  }

  &[aria-expanded="true"] {

    .fa-times {
      display: inline;
    }

    .fa-bars {
      display: none;
    }
  }
}

.navbar-nav {
  .nav-item {
    display: inline-flex;
    align-items: center;
    position: relative;
  }

  .nav-item::before {
    content: " ";
    margin: 1rem;
    height: 100%;
    width: 1px;
    transform: rotate(25deg);
    display: block;
    background: rgba(83,47,100,.5);
  }

  .nav-item::after {
    content: " ";
    position: absolute;
    left: 1rem;
    top: 0;
    bottom: 0;
    right: auto;
    background: rgba(83,47,100,1);
    height: 100%;
    width: 0;
    z-index: 1;
    transform: skewX(-25deg);
    transition: width 1s;
  }

  .nav-item:hover::after {
    width: 100%;
  }

  .nav-item:hover a {
    color: white !important;
  }

  .nav-item a {
    position: relative;
    z-index: 2;
    transition: color 1s;
  }

  li:first-child::before {
    background: transparent;
  }

  @include mediaQuery($max, 1600px) {
    .nav-item::before {
      padding: 0px;
    }
  }
}

// ============================
// Breadcrumbs
// ============================

a.breadcrumb-text.text-black.bread-hover {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

@media(min-width: 1500px) {
  .navbar .navbar-toggle-container {
    display: none !important;
  }

  .navbar-expand-xcl {
    .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
    }

    .navbar-nav {
      flex-direction: row !important;
    }
  }

  .d-xcl-none {
    display: none !important;
  }
  .order-xcl-2 {
    order: 2 !important;
  }
  .order-xcl-3 {
    order: 3 !important;
  }
  .flex-xcl-column {
    flex-direction: column !important;
  }
  .align-items-xcl-end {
    align-items: end !important;
  }
  .justify-content-xcl-between {
    justify-content: space-between !important;
  }
}
