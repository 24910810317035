@import "_variables";
@import "_placeholders";
@import "_mixins";

// ============================
// Dots
// ============================

.slider-dots {
  @extend %flex-center;

  ul {
    padding: 0;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  li {
    position: relative;
    display: inline-block;
    padding: 0;
    cursor: pointer;

    @include mediaQuery($max, $md) {
      margin: 0;
    }

    &:only-child {
      display: none;
    }
  }

  li button {
    font-size: 0;
    line-height: 0;
    display: block;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;

    &::before {
      font-size: 30px;
      line-height: 30px;
      @extend %flex-center;
      content: "•";
      text-align: center;
      color: fade-out($black, 0.75);
    }
  }

  &--top {
    position: absolute;
    bottom: 0.5rem;
    right: 0;
    left: 0;

    @include mediaQuery($max, $md) {
      bottom: 0;
    }

    li button::before {
      color: fade-out($white, 0.5);
    }
  }
}
