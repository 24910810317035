@import "_variables";
@import "_placeholders";
@import "_mixins";

// ============================
// Login
// ============================

.login__wrapper {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: darken($white, 5%);
  z-index: 10005;
}

.login__content {
  position: relative;
  z-index: 2;
  background: $white;
  width: 500px;
  max-width: 100%;

  @include mediaQuery($min, $lg) {
    margin-top: 200px;
  }
}
