@import "_variables";
@import "_placeholders";
@import "_mixins";

// ============================
// Nav Toggler
// ============================

.footer {
  clear: both;
  background: darken($white, 7%);
}

.sosumi {
  font-size: .9rem;
}
