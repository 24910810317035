.cookies-notification {
  position: fixed;
  bottom: 0;
  background-color: rgb(43, 43, 43);
  color: white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  z-index: 2000;
}
