@import "_variables";

// Custom.scss
// Option B: Include parts of Bootstrap

// variable overrides
$enable-responsive-font-sizes: true;
$primary: $brand;
$success: $green;
$warning: $yellow;
$info: $blue;
$secondary: $brown;
$danger: $purple;
$body-bg: darken($white, 3%);
$body-color: $dark-grey;
$font-family-sans-serif: $primary-font;
$headings-font-family: $secondary-font;
$headings-font-weight: 700;
$headings-margin-bottom: 1rem;
$input-btn-focus-color: fade-out($brand, 0.75);
$input-focus-border-color: $brand;

// pagination variable overrides
$pagination-color: fade-out($black, 0.25);
$pagination-hover-bg: fade-out($brand, 0.75);
$pagination-hover-border-color: fade-out($brand, 0.5);
$pagination-active-color: $white;
$pagination-active-bg: $brand;
$pagination-active-border-color: $brand;

// alert variable overrides
$alert-margin-bottom: 0;
$alert-border-radius: 0;

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Bootstrap and its default variables
@import "node_modules/bootstrap/scss/bootstrap";
